
import { reportData, options, notify, subscribeEvent } from './index';
import {
  _global,
  EVENTTYPES, HTTPTYPE, EMethods,
  OTHERTYPE,
  BASETYPE,
  replaceAop,
  getTimestamp, throttle,variableTypeDetection,on,

  ReplaceHandler, voidFun
} from '../utils';




function isFilterHttpUrl(url: string): boolean {
  return options.filterXhrUrlRegExp && options.filterXhrUrlRegExp.test(url);
}
function replace(type: EVENTTYPES): void {
  switch (type) {
    case EVENTTYPES.XHR:
      xhr();
      break;
    case EVENTTYPES.FETCH:
      fetchReplace();
      break;
    case EVENTTYPES.ERROR:
      listenError();
      break;
    case EVENTTYPES.UNHANDLEDREJECTION:
      unhandledrejectionReplace();
      break;
    case EVENTTYPES.CLICK:
      domReplace();
      break;
    default:
      break;
  }
}
export function addRewriteHandler(handler: ReplaceHandler): void {
  if (!subscribeEvent(handler)) return;
  replace(handler.type);
}
function xhr(): void {
  if (!(OTHERTYPE.XMLHTTPREQUEST in _global)) {
    return;
  }
  const originalXhrProto = XMLHttpRequest.prototype;
  replaceAop(originalXhrProto, OTHERTYPE.OPEN, (originalOpen: voidFun) => {
    return function (this: any, ...args: any[]): void {
      this.wewoo_xhr = {
        method: variableTypeDetection.isString(args[0]) ? args[0].toUpperCase() : args[0],
        url: args[1],
        sTime: getTimestamp(),
        type: HTTPTYPE.XHR,
      };
      originalOpen.apply(this, args);
    };
  });
  replaceAop(originalXhrProto, OTHERTYPE.SEND, (originalSend: voidFun) => {
    return function (this: any, ...args: any[]): void {
      const { method, url } = this.wewoo_xhr;
      on(this, OTHERTYPE.LOADEND, function (this: any) {
        if (
          (method === EMethods.Post && reportData.isSdkTransportUrl(url)) ||
          isFilterHttpUrl(url)
        )
          return;
        const { responseType, response, status } = this;
        this.wewoo_xhr.request = args[0];
        const eTime = getTimestamp();
        // 设置该接口的time，用户用户行为按时间排序
        this.wewoo_xhr.time = this.wewoo_xhr.sTime;
        this.wewoo_xhr.Status = status;
        if (['', 'json', 'text'].indexOf(responseType) !== -1) {
          // 用户设置handleHttpStatus函数来判断接口是否正确，只有接口报错时才保留response
          if (options.handleHttpStatus && typeof options.handleHttpStatus == BASETYPE.FUNCTION) {
            this.wewoo_xhr.response = response && JSON.parse(response);
          }
        }

        this.wewoo_xhr.elapsedTime = eTime - this.wewoo_xhr.sTime;
        notify(EVENTTYPES.XHR, this.wewoo_xhr);
      });
      originalSend.apply(this, args);
    };
  });
}
function fetchReplace(): void {
  if (!(HTTPTYPE.FETCH in _global)) {
    return;
  }
  replaceAop(_global, EVENTTYPES.FETCH, originalFetch => {
    return function (url: any, config: Partial<Request> = {}): void {
      const sTime = getTimestamp();
      const method = (config && config.method) || EMethods.Get;
      let fetchData = {
        type: HTTPTYPE.FETCH,
        method,
        request: config && config.body,
        url,
        response: '',
      };

      const headers = new Headers(config.headers || {});
      Object.assign(headers, {
        setRequestHeader: headers.set,
      });
      config = Object.assign({}, config, headers);
      return originalFetch.apply(_global, [url, config]).then(
        (res: any) => {
          // 克隆一份，防止被标记已消费
          const tempRes = res.clone();
          const eTime = getTimestamp();
          fetchData = Object.assign({}, fetchData, {
            elapsedTime: eTime - sTime,
            Status: tempRes.status,
            time: sTime,
          });
          tempRes.text().then((data: any) => {
            // 同理，进接口进行过滤
            if (
              (method === EMethods.Post && reportData.isSdkTransportUrl(url)) ||
              isFilterHttpUrl(url)
            )
              return;

            if (options.handleHttpStatus && typeof options.handleHttpStatus == BASETYPE.FUNCTION) {
              fetchData.response = data;
            }
            notify(EVENTTYPES.FETCH, fetchData);
          });
          return res;
        },

        (err: any) => {
          const eTime = getTimestamp();
          if (
            (method === EMethods.Post && reportData.isSdkTransportUrl(url)) ||
            isFilterHttpUrl(url)
          )
            return;
          fetchData = Object.assign({}, fetchData, {
            elapsedTime: eTime - sTime,
            status: 0,
            time: sTime,
          });
          notify(EVENTTYPES.FETCH, fetchData);
          throw err;
        }
      );
    };
  });
}
function listenError(): void {
  on(
    _global,
    EVENTTYPES.ERROR,
    function (e: ErrorEvent) {
      notify(EVENTTYPES.ERROR, e);
    },
    true
  );
}

function unhandledrejectionReplace(): void {
  on(_global, EVENTTYPES.UNHANDLEDREJECTION, function (ev: PromiseRejectionEvent) {
    notify(EVENTTYPES.UNHANDLEDREJECTION, ev);
  });
}
function domReplace(): void {
  if (!(OTHERTYPE.DOCUMENT in _global)) return;
  // 节流，默认0s
  const clickThrottle = throttle(notify, options.throttleDelayTime);
  on(
    _global.document,
    EVENTTYPES.CLICK,
    function (this: any): void {
      clickThrottle(EVENTTYPES.CLICK, {
        category: EVENTTYPES.CLICK,
        data: this,
      });
    },
    true
  );
}

