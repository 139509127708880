
/**
 * 接口错误状态
 */
export enum SpanStatus {
    Ok = 'ok',
    DeadlineExceeded = 'deadline_exceeded',
    Unauthenticated = 'unauthenticated',
    PermissionDenied = 'permission_denied',
    NotFound = 'not_found',
    ResourceExhausted = 'resource_exhausted',
    InvalidArgument = 'invalid_argument',
    Unimplemented = 'unimplemented',
    Unavailable = 'unavailable',
    InternalError = 'internal_error',
    UnknownError = 'unknown_error',
    Cancelled = 'cancelled',
    AlreadyExists = 'already_exists',
    FailedPrecondition = 'failed_precondition',
    Aborted = 'aborted',
    OutOfRange = 'out_of_range',
    DataLoss = 'data_loss',
  }
  

  export enum STATUS_CODE {
    ERROR = 'error',
    OK = 'ok',
  }
  
  /**
   * 事件类型
   */
  export enum EVENTTYPES {
    XHR = 'xhr',
    FETCH = 'fetch',
    CLICK = 'click',
    ERROR = 'error',
    UNHANDLEDREJECTION = 'unhandledrejection',
    RESOURCE = 'resource',  //资源加载报错
    DOM = 'dom',
    VUE = 'vue',
    CUSTOM = 'custom',
    HISTORY = 'history',
  }
  
  export enum HTTPTYPE {
    XHR = 'xhr',
    FETCH = 'fetch',
  }
  
  export enum HTTP_CODE {
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
  }
  
  export enum EMethods {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Delete = 'DELETE',
  }
  

  export enum OTHERTYPE {
    OPEN = 'open',
    SEND = 'send',
    XMLHTTPREQUEST = 'XMLHttpRequest',
    LOADEND= 'loadend',
    DOCUMENT = 'document',
    PROMISE = 'Promise',
    CONTENTTYPE='text/plain',
    // CONTENTTYPE='text/plain;charset=UTF-8',
    REQUESTIDLECALLBACK =  'requestIdleCallback',
    PC = 'Pc',
    UNKNOW = 'Unknow',
    SKDNAME = 'error',
  

  }

  export enum BASETYPE {
    FUNCTION = 'function',
    STRING = 'string',
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    REGEXP  = 'regexp',
    OBJECT = 'object',
    UNDEFINED = 'undefined',
  }


  export enum OPTIONS {
    DNS = 'dsn',  
    APIKEY = 'sysCode', // 每个项目对应的唯一标识
    APPCODE = 'appCode', // 每个项目对应的唯一appCode
    USERID = 'userId',  // 用户id
    UUID = 'uuid',  
    ERRORDSN = 'errorDsn', // 监控上报接口的地址
    THROTTLEDELAYTIME  = 'throttleDelayTime',  //click事件的节流时长
    OVERTIME = 'overTime',  
    FILTERXHRURLREGEXP = 'filterXhrUrlRegExp',    
    HANDLEHTTPSTATUS = 'handleHttpStatus', //处理接口返回的 response
    REPEATCODEERROR = 'repeatCodeError',  //开启去重上报
    USEIMGUPLOAD = 'useImgUpload',  
    GETUSERID = 'getUserId', 
    BEFOREDATAREPORT = 'beforeDataReport',  
    
  }


